.ol-tooltip {
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: white;
  padding: 4px 8px;
  opacity: 0.7;
  white-space: nowrap;
  font-size: 12px;
  cursor: default;
  user-select: none;
}

.ol-tooltip-measure {
  opacity: 1;
  font-weight: bold;
}

.ol-tooltip-static {
  background-color: #FF5F00;
  color: #fff;
  border: 1px solid white;
}

.ol-tooltip-measure:before,
.ol-tooltip-static:before {
  border-top: 6px solid rgba(0, 0, 0, 0.5);
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  content: "";
  position: absolute;
  bottom: -6px;
  margin-left: -7px;
  left: 50%;
}

.ol-tooltip-static:before {
  border-top-color: #FF5F00;
}

.form-inline {
  /* position: absolute;
  right: 50px;
  top: 14px; */
  display: none;
}

.form-inline label {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 4px;
  width: 37px;
  height: 37px;
  display: inline-block;
  text-align: center;
  font-size: 18px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  line-height: 1.9;
  cursor: pointer;
}

.form-inline input {
  visibility: hidden;
  position: absolute;
}

.form-inline label.active {
  background-color: #0E49B5;
}

.measure {
  position: absolute;
  right: 12px;
  top: 12px;
  display: flex;
  transition: right 0.2s linear;
}

.dra {
  position: relative;
  top: 0;
  left: 0;

}

.drawing {
  right: 120px;
  border-radius: 0;
  line-height: 25px;
  top: 16px;
  width: 32px;
  height: 34px;
  padding: 0;
  line-height: 0.4;
  background-color: #eeeeee;
}

.drawing .form-inline {
  display: flex;
}