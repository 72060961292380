.wrapper-biendong {
    display: flex;
    width: 100%;
    height: calc(100vh - 88px);
}

.form-biendong.form {
    height: 100%;
    width: 40%;
    position: relative;
}

.form-biendong .formControl {
    display: flex;
}

.form-biendong .form-actions {
    width: 100%;
    text-align: right;
    margin-top: 12px;
}

.form-biendong .property {
    padding: 8px;
}

.form-biendong .geometry {
    padding: 8px;
    overflow: auto;
    max-height: 180px;
}

.form-biendong .group {
    display: flex;
    width: 100%;
    align-items: end;
    gap: 8px;
}

.form-biendong .inputGroup {
    width: 50%;
}

.form-biendong .input {
    width: 100%;
    font-size: 12px;
}

.form-biendong .order-ht {
    width: 30px;
    height: 30px;
    text-align: center;
}

.form-biendong .geometry .btn-remove-ht {
    all: unset;
}

.form-biendong .geometry .btn-remove-ht:hover {
    background-color: #e7e7e7;
}

.view-content table tbody tr.row-actived {
    background-color: aqua;
}

#mapThuaEl {
    width: 70%;
    height: 100%;
    position: relative;
}

#mapThuaEl .control {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    pointer-events: none;
}

#mapThuaEl .control .search {
    width: 500px;
    height: 80px;
    background-color: transparent;
    /* position: absolute;
    top: -16px;
    left: 0px;
    z-index: 2; */
    pointer-events: auto;
    padding: 8px;
    display: flex;
    column-gap: 4px;
    border-radius: 4px;
    align-items: center;
}

#mapThuaEl .control .search button {
    width: 34px;
    height: 34px;
    margin-top: 22px;
}

#mapThuaEl .selected-list {
    position: relative;
    left: 10px;
    background-color: transparent;
    width: 200px;
}

#mapThuaEl .control .selected-list .selected-list-item {
    padding: 8px 16px;
    margin-bottom: 4px;
    background-color: #fff;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    left: -200px;
    -webkit-animation: slide 0.3s forwards;
    animation: slide 0.5s forwards;
    pointer-events: auto;
    cursor: pointer;
}

@-webkit-keyframes slide {
    100% {
        left: 0;
    }
}

@keyframes slide {
    100% {
        left: 0;
    }
}


#mapThuaEl .selected-list .selected-list-item .del-action {
    padding-left: 12px;
}

.form-biendong table {
    width: 100%;
}

.form-biendong table th {
    word-spacing: -1px;
    padding: 4px;
}

.form-biendong table tr.actived {
    background-color: cornflowerblue;
}

.form-biendong .main-action {
    position: absolute;
    bottom: 14px;
    right: 0;
    left: 0;
    width: 100%;
    height: 42px;
    padding: 6;
    text-align: center;
}

.form-biendong .btn-complete {
    display: inline-block;
    width: 90%;
}