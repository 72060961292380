.wrap-spatial {
  text-align: center;
  height: 100%;
  overflow-y: auto;
}

.group-spatial {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.spatial {
  padding: 0 4px;
  width: 152px;
}

.spatial input {
  width: 100%;
  height: 28px;
}

.btn-spatial {
  all: unset;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-bottom: 2px;
  background-color: #fafafa;
  background-color: #fafafa;
}

.btn-remove {}

.btn-remove:hover {
  color: #fff;
  background-color: #e91818;
}

.btn-add {}

.btn-add:hover {
  color: #fff;
  background-color: rgb(41, 101, 252);
}

.order-spatial {
  width: 26px;
  height: 26px;
  background-color: #fff;
  color: #008100;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
  border-width: 2px;
  font-weight: 600;
  margin-bottom: 3px;
  border-style: inset;
}


.pseudo {
  background-color: rgba(0, 0, 0, 0.1) !important;
}