.select-float-label {
    width: 100%;
    position: relative;
    background: #fff;
    height: 32px;
    margin-top: 22px;
}

.select-float-label>select {
    box-sizing: border-box;
    background: transparent;
    border: none;
    outline: none;
    font-size: 0.9rem;
    border-radius: 4px;
    padding: 8px;
    text-shadow: 1px 1px 0 #fff;
    width: 100%;
    transition: all 0.2s ease-in-out;
    outline: solid 1px #999999;
    margin: 0;
}

.select-float-label>select:focus {
    outline: solid 2px #2784ff;
}

.select-float-label label>span {
    color: red;
}

.select-float-label>select+label {
    top: -10px;
    left: 15px;
    color: #333c5f;
    font-size: 0.8rem;
    line-height: 0.8rem;
    transition: 0.3s;
    padding: 0 4px;
    background: white;
    z-index: 2;
}

.select-float-label>label {
    position: absolute;
    top: 8px;
    left: 15px;
    color: #808080;
    font-size: 0.9rem;
}