.input-float-label {
    width: 100%;
    position: relative;
    background: #fff;
    height: 32px;
    margin-top: 22px;
}

.input-float-label>.input {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    appearance: none;
    box-sizing: border-box;
    background: transparent;
    border: none;
    outline: none;
    font-size: 1rem;
    border-radius: 4px;
    padding: 16px;
    text-shadow: 1px 1px 0 #fff;
    width: 100%;
    outline: solid 1px #999999;
}

.input-float-label>.input:focus {
    outline: solid 2px #2784ff;
}

.input-float-label>.input.focus+label {
    top: -10px;
    left: 15px;
    color: #333c5f;
    font-size: 0.8rem;
    line-height: 0.8rem;
    transition: 0.3s;
    padding: 0 4px;
    background: white;
    z-index: 2;
}

.input-float-label>.input.focus+label>span {
    color: red;
}

.input-float-label>label {
    position: absolute;
    top: 8px;
    left: 15px;
    color: #808080;
    font-size: 0.9rem;
    transition: top 0.1s ease-in-out;
}

.input-float-label input:disabled {
    outline-color: transparent;
}