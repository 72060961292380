.range-opacity {
    position: absolute;
    bottom: 8rem;
    right: 20px;
    width: 20px;
}

.range-opacity>label {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.range-opacity>label span {
    width: 28px;
    height: 24px;
    background: #0075ff;
    text-align: center;
    line-height: 1.5;
    border-radius: 2px;
    position: relative;
    color: #fff;
    margin-top: 6px;
    font-weight: 600;
}

.range-opacity>label span::before {
    content: "";
    border-width: 4px;
    border-color: transparent;
    position: absolute;
    border-style: solid;
    bottom: 100%;
    border-bottom-color: #0075ff;
    right: calc(50% - 4px);
}

.range-opacity #opacity-input {
    height: 200px;
    border-radius: 5px;
    background: #ccc;
    outline: none;
    writing-mode: vertical-lr;
    /* IE */
    -webkit-appearance: slider-vertical;
    /* WebKit */
    width: 20px;

}