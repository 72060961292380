/* :root {
    --gap-tootip: 12px;
}

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip-box {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    display: none;
    white-space: nowrap;
    font-size: 14px;
    z-index: 99;
}

.tooltip-box.right {
    left: calc(100% + var(--gap-tootip));
    top: calc(50% - 13px);
}

.tooltip-box.left {
    right: calc(100% + var(--gap-tootip));
    top: calc(50% - 13px);
}

.tooltip-box.top {
    bottom: calc(100% + var(--gap-tootip));
}

.tooltip-box.bottom {
    top: calc(100% + var(--gap-tootip));
}


.tooltip-box.visible {
    display: block;
}

.tooltip-arrow {
    position: absolute;

    border-width: 5px;
    border-style: solid;

}

.tooltip-box.right .tooltip-arrow {
    right: 100%;
    border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent;
    transform: translateY(50%)
}

.tooltip-box.left .tooltip-arrow {
    left: 100%;
    border-color: transparent transparent transparent rgba(0, 0, 0, 0.7);
    transform: translateY(50%)
}

.tooltip-box.top .tooltip-arrow {
    top: 100%;
    border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
    transform: translateX(50%)
}

.tooltip-box.bottom .tooltip-arrow {
    bottom: 100%;
    border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
    transform: translateX(50%)
}

@media screen and (max-width: 480px) {
    .tooltip-box.visible {
        display: none;
    }
} */

.cell {
    cursor: pointer;
    position: relative;
    background: #f7f5f100;
    cursor: pointer;
    position: relative;
    border-color: #b4a078;
}

.cell[class*=poptip--]::before,
.cell[class*=poptip--]::after {
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, 0, 0);
    transition: all .3s ease .05s;
}

.cell[class*=poptip--]:hover::before,
.cell[class*=poptip--]:hover::after {
    visibility: visible;
    opacity: 1;
}

.cell[class*=poptip--]::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border: 6px solid transparent;
}

.cell[class*=poptip--]::after {
    content: attr(aria-controls);
    position: absolute;
    background: #b4a078;
    font-size: 12px;
    font-weight: normal;
    color: white;
    line-height: 12px;
    padding: 6px 12px;
    white-space: nowrap;
    border-radius: 2px;
    box-shadow: 0px 0px 3px #b4a078;
    z-index: 9999;
}

.cell[class*=poptip--top]::before {
    border-top-color: inherit;
}

.cell[class*=poptip--right]::before {
    border-right-color: inherit;
}

.cell[class*=poptip--bottom]::before {
    border-bottom-color: inherit;
}

.cell[class*=poptip--left]::before {
    border-left-color: inherit;
}

/* top && bottom */
.cell[class*=poptip--top]::before,
.cell[class*=poptip--bottom]::before {
    left: calc(50% - 6px);
}

.cell[class*=poptip--top]::after,
.cell[class*=poptip--bottom]::after {
    left: 50%;
    transform: translateX(-50%);
}

.cell[class*=poptip--top]::before {
    top: 0px;
}

.cell[class*=poptip--top]:hover::before {
    transform: translateX(0%) translateY(-10px);
}

.cell[class*=poptip--top]::after {
    bottom: 100%;
    margin-bottom: -1px;
}

.cell[class*=poptip--top]:hover::after {
    transform: translateX(-50%) translateY(-10px);
}

.cell[class*=poptip--bottom]::before {
    bottom: 0;
}

.cell[class*=poptip--bottom]:hover::before {
    transform: translateX(0%) translateY(10px);
}

.cell[class*=poptip--bottom]::after {
    top: 100%;
    margin-top: -1px;
}

.cell[class*=poptip--bottom]:hover::after {
    transform: translateX(-50%) translateY(10px);
}

/* left && right */
.cell[class*=poptip--right]::before,
.cell[class*=poptip--left]::before {
    top: calc(50% - 6px);
}

.cell[class*=poptip--right]::after,
.cell[class*=poptip--left]::after {
    top: 50%;
    transform: translateY(-50%);
}

.cell[class*=poptip--right]::before {
    right: 0;
}

.cell[class*=poptip--right]:hover::before {
    transform: translateX(10px) translateY(0%);
}

.cell[class*=poptip--right]::after {
    left: 100%;
    margin-left: -1px;
}

.cell[class*=poptip--right]:hover::after {
    transform: translateX(10px) translateY(-50%);
    ;
}

.cell[class*=poptip--left]::before {
    left: 0;
}

.cell[class*=poptip--left]:hover::before {
    transform: translateX(-10px) translateY(0%);
}

.cell[class*=poptip--left]::after {
    right: 100%;
    margin-right: -1px;
}

.cell[class*=poptip--left]:hover::after {
    transform: translateX(-10px) translateY(-50%);
    ;
}