.popup {
    height: 100%;
    width: 100%;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    -webkit-transition: .3s ease-in-out;
    -moz-transition: .3s ease-in-out;
    -o-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    /* padding: 0.5rem; */
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    overflow-y: auto;
    overflow-x: hidden;
}

.collapse {
    left: -430px;
}

.detail-wrapper {
    margin: 0 -8px;
    padding: 4px 16px 16px 18px;
}

.detail-group {
    display: flex;
}

.detail-group:not(:last-child) {
    border-bottom: 1px solid #efefef;
}

/* .detail-group:nth-child(even) {
    background-color: #fafafa;
} */

.detail-label,
.detail-text {
    padding: 6px;
    font-size: 0.8rem;
    color: #161515;
}

.detail-text:empty:after {
    content: "Chưa có thông tin";
    width: 100px;
    height: 100%;
    color: #b0b0b0;
    font-weight: 400;
    font-style: italic;
    font-size: 0.8rem;
}

.detail-label {
    width: 46%;
    background-color: #fafafa;
    border-right: 1px solid #efefef;
    /* text-align: right; */
}

.detail-text {
    width: 54%;
}

.btn-wrapper {
    display: flex;
    justify-content: end;
    padding-right: 12px;
}

.btn-popup {
    all: unset;
    padding: 0.5rem;
    word-wrap: break-word;
    cursor: pointer;
    width: 22px;
    height: 22px;
    font-size: 18px;
    color: antiquewhite;
    /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
    text-align: center;
    border-radius: 4px;
}

.btn-close {
    background-color: #f44336;
    margin-left: 4px;
}

.btn-edit {
    background-color: #00bcd4;

}

.collapse .btn-expand {
    /* position: absolute;
    left: 100%;
    background-color: red;
    color: #fff; */
}

/* .custom-scrollbar {
    height: 70px;
    overflow-y: scroll;
  } */


.result-item {
    padding: 8px 4px;
    margin-bottom: 12px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

.result-item h4 {
    margin-bottom: 6px;

}

.desc-hientrang {
    margin-top: 12px;
    max-height: 120px;
    display: flex;
}

.desc-hientrang .symbol {
    height: 62px;
    width: 22%;
    border-radius: 4px;
    border: 1px solid #a8a8a8;
    line-height: 2.6;
    font-size: 22px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    text-shadow: 1px 1px 4px #000;
}

.desc-hientrang .content {
    width: 88%;
    padding-left: 6px;
}

.desc-hientrang+hr {
    margin-top: 8px;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

.wrap-hientrang div:last-child>hr {
    display: none;
}

.desc-hientrang .content h4 {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: -0.4px;
    color: #111111;
    line-height: 1.1;
}

.more-detail {
    color: #0000ee !important;
    cursor: default;
}

.more-detail:hover {
    text-decoration: underline;
}


.desc-hientrang .content p {
    font-size: 13px;
    margin-top: 2px;
    color: #5b5b5b;
}

summary {
    color: rgb(11, 124, 200);
    text-decoration: underline;
}


/* map actions */

.map-actions {
    display: flex;
    justify-content: center;
}

.map-action-item {
    text-align: center;
    color: #2196f3;
    cursor: pointer;
    padding: 8px;
    width: 90px;
}

.map-action-icon {}

.map-action-text {
    letter-spacing: .025em;
    font-size: .7rem;
    font-weight: 400;
    line-height: 1rem;
}