#mapEl {
    position: absolute;
    bottom: 5px;
    right: 20px;
    width: 360px;
    height: 300px;
    z-index: 0;
}

.iframe-pdf {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    background-color: #525659;
}