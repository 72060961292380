.legend-qhpk {
    width: 100%;
}

.legend-item {
    display: flex;
    column-gap: 10px;
    padding: 4px;
}

.legend-item-color {
    height: 30px;
    width: 30px;
}

.legend-item-text {}

.legend-title {
    display: flex;
}

.legend-title-item {
    width: 33.33%;
    font-size: 12px;
    text-align: center;
    padding: 6px;
    font-weight: 600;
    border-bottom: 2px solid #e7e7e7;
    cursor: default;
}

.legend-title-item.active {
    border-color: #2196f3;
    color: #2196f3;
}

.legend-content {
    display: none;
}

.legend-content .legend-info {
    font-size: 14px;
    text-align: center;
    padding: 16px 0;
    color: #484848;
}

.legend-content.active {
    display: block;
}