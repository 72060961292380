 .form-hientrang.form {
     margin: auto 300px;
     height: 100%;
 }

 .form-hientrang .formControl {
     display: flex;
 }

 .form-hientrang .form-actions {
     width: 100%;
     text-align: right;
     margin-top: 12px;
 }

 .form-hientrang .property {
     width: 400px;
     padding: 8px;
 }

 .form-hientrang .geometry {
     width: 600px;
     padding: 8px;
     overflow: auto;
     height: 418px;
 }

 .form-hientrang .group {
     display: flex;
     width: 100%;
     align-items: end;
     gap: 8px;
 }

 .form-hientrang .inputGroup {
     width: 50%;
 }

 .form-hientrang .input {
     width: 100%;
 }

 .form-hientrang .order-ht {
     width: 30px;
     height: 30px;
     text-align: center;
 }

 .form-hientrang .geometry .btn-remove-ht {
     all: unset;
 }

 .form-hientrang .geometry .btn-remove-ht:hover {
     background-color: #e7e7e7;
 }

 .view-content table tbody tr.row-actived {
     background-color: aqua;
 }