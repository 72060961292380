.table-custom {}

/* .table-custom tr {
    border-bottom: 1px solid #e7e7e7;
} */

.table-custom th,
td {
    padding: 6px;
}

.table-custom th {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
}

.table-custom td {}

.frozen-column {
    background-color: #ddd;
    position: sticky;
    z-index: 1;
}

.frozen-column.frozen-column-left {
    left: 0;
}

.frozen-column.frozen-column-right {
    right: 0;
}

.frozen-header {
    background-color: #ddd;
    top: 0;
    position: sticky;
    z-index: 1;
}

.frozen-column.frozen-header {
    z-index: 2;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    float: right;
    margin-top: 12px;
}

li.page-item {
    border: 1px solid #0078AA;
    cursor: default;
}

ul.pagination li.active {
    background-color: #0078AA;
    color: #fff;
}

ul.pagination li:not(.active):hover {
    background-color: #ddd;
}


a.page-link {
    display: block;
    padding: 6px 12px;
}

.table-ol .btn-delete {
    border: none;
    padding: 2px 4px;
    background-color: transparent;
}

.badge {
    padding: 2px 6px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    word-spacing: -1px;
    font-size: 12px;
    margin: auto;
}

.badge.active {
    color: #3EC70B;
    background-color: aliceblue;
}

.badge.danger {
    color: red;
    background-color: #ff00001a;
}