.switch-layer {
    position: absolute;
    bottom: 0;
    left: 0;
}

.switch-layer>div {
    width: 100px;
    height: 100px;
    background-color: red;
}