.content-buiding {
  display: flex;

}

.input-text {
  width: 70%;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: .3s;
}

.input-text:focus {
  border-color: dodgerBlue;
  box-shadow: 0 0 8px 0 dodgerBlue;
}

.button-85 {
  padding: 0.6em 2em;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-85:before {
  content: "";
  background: linear-gradient(45deg,
      #ff0000,
      #ff7300,
      #fffb00,
      #48ff00,
      #00ffd5,
      #002bff,
      #7a00ff,
      #ff00c8,
      #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button-85 20s linear infinite;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

@keyframes glowing-button-85 {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

.button-85:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #222;
  left: 0;
  top: 0;
  border-radius: 10px;
}

.box-item {
  align-items: center;
  padding: 1rem 6px 0.5rem 6px;
  box-shadow: rgb(17 17 26 / 10%) 0px 1px 0px;
}

.box-item h4 {
  margin-bottom: 6px;
}

.box-item p {
  font-size: 14px;
}

.box-item:hover {
  background-color: #fafafa;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.div-result {
  height: calc(100% - 110 px);
}

.address .group-control {
  display: flex;
  flex-wrap: wrap;
  column-gap: 6px;
}

.address .form-control {
  flex: 1 1 160px;
}