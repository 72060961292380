.tab-wrapper {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 380px;
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgb(53 72 91 / 7%), 0 2px 2px rgb(0 0 0 / 1%), 0 4px 4px rgb(0 0 0 / 2%), 0 10px 8px rgb(0 0 0 / 3%), 0 15px 15px rgb(0 0 0 / 3%), 0 30px 30px rgb(0 0 0 / 4%), 0 70px 65px rgb(0 0 0 / 5%);
    transition: all 0.2s linear;
}

.tab-content {
    height: 100%;
    width: 100%;
}

.tab-btn {
    position: relative;
    height: 100%;
    width: 100%;
}

.wrapper-btn {
    position: absolute;
    left: calc(100% + 10px);
    top: 0;
    z-index: 1;
}

.wrapper-btn .tooltip {
    margin-top: 12px;
}

.btn {
    display: block;
    background-color: #fff;
    border-radius: 0.5rem;
    cursor: pointer;
}

.btn:hover {
    transform: scale(1.1);
}

.collapse-tab {
    left: -380px;
}

.tab-item {
    display: none;
    height: 100%;
}

.tab-show {
    display: block;
}

.btn-active {
    border-color: #2196f3;
    color: white;
}

.btn-collapse {
    all: unset;
    position: absolute;
    top: calc(50% - 25px);
    left: 100%;
    height: 50px;
    width: 20px;
    z-index: 999;
    border-radius: 0 4px 4px 0;
    background: linear-gradient(90deg, white, #c6c6c6);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    cursor: pointer;
}

.btn-collapse:hover {
    background: #2196f3;
}

.btn-collapse:hover svg polygon {
    fill: #fff;
}

@media screen and (max-width: 576px) {
    .tab-wrapper {
        inset: unset;
        left: 0;
        height: 60vh;
        width: -webkit-fill-available;
        bottom: 0;
    }

    .wrapper-btn {
        inset: unset;
        bottom: calc(100% + 10px) !important;
        justify-content: space-between;
        display: flex;
        gap: 1rem;
        margin: 0 auto;
        left: calc(50% - 71px);
    }

    .wrapper-btn .btn {
        display: inline;

    }

    .collapse-tab {
        inset: unset;
        bottom: -60vh;
    }

    .btn-collapse {
        display: none;
    }


    /* .tab-content {
        position: absolute;
        height: 50vh;
        width: 100%;
        background-color: #fff;
        z-index: 1;
        top: 0;
        margin-top: 110%;
    }
    
    .tab-btn {
        position: relative;
        z-index: 1;
        top:0;
        left: 0;
        height: 100%;
        width: 100%;
    }
    .wrapper-btn {
        position: absolute;
        left: 0%;
        top: 0;
    }
    
    .btn {
        display: block;
        padding: 15px;
        background-color: #fff;
        margin-top: 5px;
        border-radius: 1rem;
    }
    
    .collapse {
        left: 0px;
    } */
}

.switch-basemap {
    position: absolute;
    width: 90px;
    height: 90px;
    background: rgba(70, 70, 70, 0.264);
    left: calc(100% + 10px);
    bottom: 10px;
}

.switch-basemap>div:not(:last-child) {
    position: absolute;
    top: 0;
    left: 0;
    height: inherit;
    width: inherit;
    visibility: hidden;
}

.switch-basemap div.behavior-expected {
    visibility: visible;
}

.switch-basemap .street {
    background-color: green;
}

.switch-basemap .lite {
    background-color: blue;
}

.switch-basemap .layer-more {
    position: absolute;
    left: calc(100% + 10px);
    top: 0;
    width: 300px;
    height: inherit;
    background-color: rgba(255, 255, 255, 0.714);
}