#map {
  width: 100%;
  height: 100%;
}

/* canvas.ol-legendImg {
  width: 100%;
} */

ul.ol-legend {
  position: unset !important;
  inset: unset !important;
  display: none;
  overflow: scroll;
}


#legendElQh {
  height: 100%;
  width: 100%
}

.legend-custom {
  height: 100%;
  overflow-y: auto;
}

.ol-control.ol-geobt {
  bottom: 3em !important;
}

/* switch */
.ol-control.ol-layerswitcher {
  max-height: calc(100% - 10em) !important;
}

.ol-control.ol-layerswitcher {
  right: 0.6em !important;
  top: 3.2em !important;
}

.ol-layerswitcher li .li-content label {
  max-width: 16rem !important;
}

.ol-layerswitcher .layerswitcher-opacity {
  display: none;
}