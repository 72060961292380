.button-shared {
    padding: 0 12px;
    background-color: #06f;
    color: #fff;
    font-weight: 600;
    /* letter-spacing: -.2px; */
    line-height: 26px;
    box-sizing: border-box;
    border: 2px solid #06f;
    fill: #000;
    transition: all .3s;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
}

.button-shared:hover {
    background-color: #3385ff;
    border-color: #3385ff;
    fill: #06f;
}

.button-shared.outline {
    border: 1px solid #06f;
    color: #06f;
    background-color: #fff;
}

.button-shared.outline:hover {
    background-color: #3385ff;
    border-color: #3385ff;
    fill: #06f;
    color: #fff;
}

.button-shared:disabled {
    background-color: #CFD2CF;
    color: #525252;
    border-color: transparent;

}

.button-shared.btn-icon {
    padding: 4px;
}

.button-shared.btn-danger {
    background-color: #FF1700;
    color: #fff;
    border: 2px solid #FF1700;
}

.button-shared.btn-danger:hover {
    background-color: #fe5c4d;
    border-color: #fe5c4d;
    fill: #FF1700;
}

.button-shared.btn-danger.outline {
    border: 1px solid #FF1700;
    color: #FF1700;
    background-color: #fff;
}

.button-shared.btn-danger.outline:hover {
    background-color: #ff4434;
    border-color: #ff4434;
    fill: #FF1700;
    color: #fff;
}