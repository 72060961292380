*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html,
body {
    line-height: 1.2;
}


/* button {
    all: unset;
  } */

.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #efefef;
    border-radius: 12px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #b3b3b3;
    border-radius: 12px;
}