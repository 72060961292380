/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
body,
html {
  font-family: sans-serif !important;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

@font-face {
  font-family: 'helvetica';
  font-weight: 900;
  src: local('helvetica'), url('/public/helvetica-neue-regular.ttf') format('truetype');
}

#root {
  height: 100%;
}

:root {
  --with-tab: 390px;
}

/* map css */
.ol-box {
  box-sizing: border-box;
  border-radius: 2px;
  border: 1.5px solid #b3c5db;
  background-color: rgba(255, 255, 255, .4)
}

.ol-mouse-position {
  top: 8px;
  right: 8px;
  position: absolute
}

.ol-scale-line {
  background: rgba(0, 60, 136, .3);
  border-radius: 4px;
  bottom: 8px;
  left: 8px;
  padding: 2px;
  position: absolute
}

.ol-scale-line-inner {
  border: 1px solid #eee;
  border-top: none;
  color: #eee;
  font-size: 10px;
  text-align: center;
  margin: 1px;
  will-change: contents, width;
  transition: all .25s
}

.ol-scale-bar {
  position: absolute;
  bottom: 8px;
  left: 8px
}

.ol-scale-step-marker {
  width: 1px;
  height: 15px;
  background-color: #000;
  float: right;
  z-index: 10
}

.ol-scale-step-text {
  position: absolute;
  bottom: -5px;
  font-size: 12px;
  z-index: 11;
  color: #000;
  text-shadow: -2px 0 #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff
}

.ol-scale-text {
  position: absolute;
  font-size: 14px;
  text-align: center;
  bottom: 25px;
  color: #000;
  text-shadow: -2px 0 #fff, 0 2px #fff, 2px 0 #fff, 0 -2px #fff
}

.ol-scale-singlebar {
  position: relative;
  height: 10px;
  z-index: 9;
  box-sizing: border-box;
  border: 1px solid #000
}

.ol-unsupported {
  display: none
}

.ol-unselectable,
.ol-viewport {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent
}

.ol-viewport canvas {
  all: unset
}

.ol-selectable {
  -webkit-touch-callout: default;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text
}

.ol-grabbing {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing
}

.ol-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab
}

.ol-control {
  position: absolute;
  background-color: rgba(255, 255, 255, .4);
  border-radius: 4px;
  padding: 2px
}

.ol-control:hover {
  background-color: rgba(255, 255, 255, .6)
}

.ol-zoom {
  top: .5em;
  left: .5em
}

.ol-rotate {
  top: .5em;
  right: .5em;
  transition: opacity .25s linear, visibility 0s linear
}

.ol-rotate.ol-hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s linear, visibility 0s linear .25s
}

.ol-zoom-extent {
  top: 4.643em;
  left: .5em
}

.ol-full-screen {
  right: .5em;
  top: .5em
}

.ol-control button {
  display: block;
  /* margin: 1px; */
  padding: 0;
  color: #fff;
  font-weight: 700;
  text-decoration: none;
  font-size: inherit;
  text-align: center;
  height: 1.375em;
  width: 1.375em;
  line-height: .4em;
  background-color: rgba(0, 60, 136, .5);
  border: none;
  border-radius: 2px
}

.ol-control button::-moz-focus-inner {
  border: none;
  padding: 0
}

.ol-zoom-extent button {
  line-height: 1.4em
}

.ol-compass {
  display: block;
  font-weight: 400;
  font-size: 1.2em;
  will-change: transform
}

.ol-touch .ol-control button {
  font-size: 1.5em
}

.ol-touch .ol-zoom-extent {
  top: 5.5em
}

.ol-control button:focus,
.ol-control button:hover {
  text-decoration: none;
  background-color: rgba(0, 60, 136, .7)
}

.ol-zoom .ol-zoom-in {
  border-radius: 2px 2px 0 0
}

.ol-zoom .ol-zoom-out {
  border-radius: 0 0 2px 2px
}

.ol-attribution {
  text-align: right;
  bottom: .5em;
  right: .5em;
  max-width: calc(100% - 1.3em);
  display: flex;
  flex-flow: row-reverse;
  align-items: center
}

.ol-attribution a {
  color: rgba(0, 60, 136, .7);
  text-decoration: none
}

.ol-attribution ul {
  margin: 0;
  padding: 1px .5em;
  color: #000;
  text-shadow: 0 0 2px #fff;
  font-size: 12px
}

.ol-attribution li {
  display: inline;
  list-style: none
}

.ol-attribution li:not(:last-child):after {
  content: " "
}

.ol-attribution img {
  max-height: 2em;
  max-width: inherit;
  vertical-align: middle
}

.ol-attribution button {
  flex-shrink: 0
}

.ol-attribution.ol-collapsed ul {
  display: none
}

.ol-attribution:not(.ol-collapsed) {
  background: rgba(255, 255, 255, .8)
}

.ol-attribution.ol-uncollapsible {
  bottom: 0;
  right: 0;
  border-radius: 4px 0 0
}

.ol-attribution.ol-uncollapsible img {
  margin-top: -.2em;
  max-height: 1.6em
}

.ol-attribution.ol-uncollapsible button {
  display: none
}

.ol-zoomslider {
  top: 4.5em;
  left: .5em;
  height: 200px
}

.ol-zoomslider button {
  position: relative;
  height: 10px
}

.ol-touch .ol-zoomslider {
  top: 5.5em
}

.ol-overviewmap {
  left: .5em;
  bottom: .5em
}

.ol-overviewmap.ol-uncollapsible {
  bottom: 0;
  left: 0;
  border-radius: 0 4px 0 0
}

.ol-overviewmap .ol-overviewmap-map,
.ol-overviewmap button {
  display: block
}

.ol-overviewmap .ol-overviewmap-map {
  border: 1px solid #7b98bc;
  height: 150px;
  margin: 2px;
  width: 150px
}

.ol-overviewmap:not(.ol-collapsed) button {
  bottom: 2px;
  left: 2px;
  position: absolute
}

.ol-overviewmap.ol-collapsed .ol-overviewmap-map,
.ol-overviewmap.ol-uncollapsible button {
  display: none
}

.ol-overviewmap:not(.ol-collapsed) {
  background: rgba(255, 255, 255, .8)
}

.ol-overviewmap-box {
  border: 2px dotted rgba(0, 60, 136, .7)
}

.ol-overviewmap .ol-overviewmap-box:hover {
  cursor: move
}

/* layer switcher */
.layer-switcher.shown.layer-switcher-activation-mode-click {
  padding-right: 34px;
}

.layer-switcher.shown.layer-switcher-activation-mode-click>button {
  right: 0;
  border-left: 0;
}

/* ----- */

.btn-control {
  width: 37px;
  height: 37px;
  line-height: .6;
  text-align: center;
  background-color: rgb(255, 255, 255);
  font-size: 24px;
  border-radius: 4px;
  border-color: transparent;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  outline: rgba(255, 255, 255, 0.418) solid 3.8px;
  cursor: pointer;
  margin: 0;
}

p.text-error {
  font-size: 13px;
  margin-top: 4px;
  color: red;
  padding: 2px 4px;
}

header {
  width: 100%;
  height: 50px;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 4px 12px;
}

.dropdown {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding: 6px;
}

.dropdown button {
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  display: flex;
  height: 32px;
  width: 128px;
  all: unset;
  padding-right: 4px;
}

/* Hide the dropdown's content by default */
.dropdown__content {
  display: none;

  /* Position it right below the trigger element */
  left: 0;
  padding-top: 4px;
  position: absolute;
  top: 100%;

  /* It should be on the top of other elements */
  background-color: #fff;
  z-index: 9999;

  /* Size */
  height: auto;
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
}

/* Show the content when hover on the container */
.dropdown:hover .dropdown__content {
  display: block;
}

.dropdown__content ul li {
  padding: 6px 12px;
  list-style-type: none
}

.dropdown__content ul li:hover {
  background-color: #e7e7e7;
}

.dropdown__content ul li a {
  text-decoration: none;
  color: #000;

}

/* table {
  border-spacing: 0;
  border: 1px solid black;
  width: 100%;
}

tr:last-child td {
  border-bottom: 0;
}


th,
td {
  margin: 0;
  padding: 0.5rem;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

th:last-child,
td:last-child {
  border-right: 0;
} */

td,
th {
  border: 1px solid rgb(190, 190, 190);
  padding: 10px;
}

td {
  text-align: lefts;
}

table.striped tr:nth-child(even) {
  background-color: #eee;
}

th[scope="col"] {
  background-color: rgb(0 102 255 / 80%);
  color: #fff;
}

th[scope="row"] {
  background-color: #d7d9f2;
}

caption {
  padding: 10px;
  caption-side: bottom;
}

table {
  border-collapse: collapse;
  border: 2px solid rgb(200, 200, 200);
  letter-spacing: .6px;
  font-family: sans-serif;
  font-size: .8rem;
}