:root {
    --color-text: white;
    --color-primary: #0167ce;
}

.section-tab {
    height: calc(100% - 32px);
    background: white;
    padding: 0.5rem;
    border-radius: 0 0 0.5rem 0.5rem;
}

.tablist {
    display: flex;
    flex-direction: row;
    gap: 1px;
}

.tablist>li {
    list-style: none;
    transition: all .5s cubic-bezier(.25, .8, .25, 1);
    background: lightgray;
    transition: opacity 0.3s;
}

.tablist:hover li:not(:hover) {
    opacity: 0.6;
}

.tablist>li>button {
    padding: 0.5rem;
    background-color: var(--color-bakcground);
    border: none;
    color: #454545;
    font-size: 1rem;
    cursor: pointer;
    letter-spacing: -0.2px;
    font-weight: 600;
    font-size: 14px;
}

.li-active {
    background-color: white !important;
}

.tablist>li>[aria-selected="true"] {
    border-bottom-color: var(--color-primary);
}

.tabs-wrapper {
    height: 100%;
}

/* From Every Layout (https://every-layout.dev/) */

.switcher>* {
    display: flex;
    flex-wrap: wrap;
    margin: calc((var(--tab-padding) / 2) * -1);
}

.switcher>*>* {
    flex-grow: 1;
    flex-basis: calc((var(--tab-width) - (100% - var(--tab-padding))) * 999);
    margin: calc(var(--tab-padding) / 2);
}

.switcher>*> :nth-last-child(n + 5),
.switcher>*> :nth-last-child(n + 5)~* {
    flex-basis: 100%;
}

@media screen and (max-width: 576px) {
    .tablist>li>button {
        font-size: 1rem;
    }

}